import Link from 'next/link';
import PropTypes from 'prop-types';
import Image from 'next/image';

// Utils
import { cn } from 'utils';

// Components
import { Picto } from '../../atoms/Picto/Picto';
import SectionFooter from '../../atoms/SectionFooter/SectionFooter';
import NavigationFooter from '../../atoms/NavigationFooter/NavigationFooter';

// Styles
import styles from './Footer.module.css';

// Constants
import { CONTACT_SLUG } from '../../../src/constants';

function Footer({
  data: {
    logo,
    title,
    titleResidences,
    rowsResidences,
    titleSocialMedias,
    rowsSocialMedias,
    titleStudentFactory,
    rowsStudentFactory,
    logoBottom,
    subLinks,
  },
}) {
  return (
    <footer className={cn([styles.footer])}>
      <div className={styles.container}>
        <Link href="/" className={styles.logoContainer}>
          {logo?.url ? (
            <Image
              fill
              src={logo?.url}
              alt={logo?.description}
              className={styles.logo}
              sizes="256px"
            />
          ) : null}
        </Link>

        <div className={styles.contactContainer}>
          {title ? (
            <Link href={`/${CONTACT_SLUG}`} className={styles.contactLink}>
              <p className={styles.contactTitle}>{title}</p>
              <Picto icon="arrowOutward" className={styles.contactPicto} />
            </Link>
          ) : null}
        </div>

        <nav className={styles.nav}>
          <SectionFooter
            title={titleResidences}
            links={rowsResidences}
          />

          <SectionFooter
            title={titleStudentFactory}
            links={rowsStudentFactory}
          />

          <SectionFooter
            title={titleSocialMedias}
            links={rowsSocialMedias}
            direction="row"
          />

          <div className={styles.logoBottomWrapper}>
            <div className={styles.logoBottomContainer}>
              {logoBottom?.url ? (
                <Image
                  fill
                  src={logoBottom?.url}
                  alt={logoBottom?.description}
                  className={styles.logoBottom}
                  sizes="256px"
                />
              ) : null}
            </div>
          </div>
        </nav>
      </div>

      <div className={styles.subContainer}>
        <div className={styles.subNav}>
          <NavigationFooter
            links={subLinks}
          />
          <span className={styles.copyright}>{`Bikube © ${new Date().getFullYear()}`}</span>
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  data: PropTypes.shape({
    logo: PropTypes.shape({
      url: PropTypes.string,
      description: PropTypes.string,
    }),
    phone: PropTypes.string,
    title: PropTypes.string,
    faqLink: PropTypes.string,
    faqLabel: PropTypes.string,
    subLinks: PropTypes.arrayOf(PropTypes.shape({
      data: PropTypes.shape({
        link: PropTypes.string,
        name: PropTypes.string,
        localName: PropTypes.string,
        triggerCookie: PropTypes.bool,
      }),
      type: PropTypes.string,
    })),
    logoBottom: PropTypes.shape({
      url: PropTypes.string,
      description: PropTypes.string,
    }),
    investContent: PropTypes.string,
    rowsResidences: PropTypes.arrayOf(PropTypes.shape({
      data: PropTypes.shape({
        link: PropTypes.string,
        name: PropTypes.string,
        localName: PropTypes.string,
      }),
      type: PropTypes.string,
    })),
    titleResidences: PropTypes.string,
    investButtonLink: PropTypes.string,
    rowsSocialMedias: PropTypes.arrayOf(PropTypes.shape({
      data: PropTypes.shape({
        link: PropTypes.string,
        source: PropTypes.string,
        localName: PropTypes.string,
      }),
      type: PropTypes.string,
    })),
    availabilityLabel: PropTypes.string,
    investButtonLabel: PropTypes.string,
    titleSocialMedias: PropTypes.string,
    rowsStudentFactory: PropTypes.arrayOf(PropTypes.shape({
      data: PropTypes.shape({
        link: PropTypes.string,
        name: PropTypes.string,
        localName: PropTypes.string,
      }),
      type: PropTypes.string,
    })),
    titleStudentFactory: PropTypes.string,
  }),
};

Footer.defaultProps = {
  data: {
    logo: {},
    phone: '',
    title: '',
    faqLink: '',
    faqLabel: '',
    subLinks: [],
    logoBottom: {},
    investContent: '',
    rowsResidences: [],
    titleResidences: '',
    investButtonLink: '',
    rowsSocialMedias: [],
    availabilityLabel: '',
    investButtonLabel: '',
    titleSocialMedias: '',
    rowsStudentFactory: [],
    titleStudentFactory: '',
  },
};

export default Footer;
