import { useMemo } from 'react';
import { useRouter } from 'next/router';
import { fileUrlToUrl } from 'next-translate-routes';
import { isEmpty } from 'lodash';

// Constants
import { LOCALE_FR, LOCALE_EN, DEFAULT_LOCALE } from '../constants';

const useSeoLinks = (prefix, relatedSlugs = {}) => {
  const { pathname, locale } = useRouter();
  const baseUrl = process.env.NEXT_PUBLIC_URL || '';

  const canonicalUrl = useMemo(() => {
    const activeLocale = `/${locale}`;
    const autoLibPathname = fileUrlToUrl({ pathname }, locale, { throwOnError: false });
    const pathnameFromSlugs = `${relatedSlugs[locale] ? `${activeLocale}/${prefix ? `${prefix}/` : ''}${relatedSlugs[locale]}` : ''}`;

    const pathName = autoLibPathname || pathnameFromSlugs;

    return `${baseUrl}${pathName}`;
  }, [relatedSlugs]);

  const getLocalePathname = (supportedLocale) => {
    const activeLocale = supportedLocale === DEFAULT_LOCALE ? `/${DEFAULT_LOCALE}` : `/${supportedLocale}`;
    const autoLibPathname = fileUrlToUrl({ pathname }, supportedLocale, { throwOnError: false });
    const pathnameFromSlugs = `${relatedSlugs[supportedLocale] ? `${activeLocale}/${relatedSlugs[supportedLocale]}` : ''}`;

    const pathName = autoLibPathname || pathnameFromSlugs;
    return pathName;
  };

  const alternateLinks = useMemo(() => {
    const supportedLocales = [LOCALE_FR, LOCALE_EN];

    return supportedLocales.reduce((acc, supportedLocale) => {
      const pathName = getLocalePathname(supportedLocale);

      const hasNoSlugRelated = (!isEmpty(relatedSlugs) && !pathName);
      if (hasNoSlugRelated) return acc;

      const isActiveLocale = locale === supportedLocale;
      if (isActiveLocale) {
        const defaultPathName = getLocalePathname(DEFAULT_LOCALE);
        return acc.concat({
          href: `${baseUrl}${defaultPathName}`,
          hrefLang: 'x-default',
        });
      }

      return acc.concat({
        href: `${baseUrl}${pathName}`,
        hrefLang: supportedLocale,
      });
    }, []);
  }, [canonicalUrl, locale]);

  return {
    canonicalUrl,
    alternateLinks,
  };
};

export default useSeoLinks;
